export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    let yy = date.getFullYear();
    let mm = date.getMonth();
    let dd = date.getDate();
    return `${dd > 9 ? dd : '0' + dd} ${months[mm]} ${yy}`;
};

export const formatedTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');
    const hours = `${now.getHours()}`.padStart(2, '0');
    const minutes = `${now.getMinutes()}`.padStart(2, '0');
    const seconds = `${now.getSeconds()}`.padStart(2, '0');
    return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
};

export const formatCustomDate = (dateString, format = 'MM/DD/YYYY') => {
    if (!dateString) return '';
    const date = new Date(dateString);
    
    // Handle invalid dates
    if (isNaN(date.getTime())) return '';
    
    switch (format) {
        case 'MM/DD/YYYY':
            return date.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
        case 'DD MMM YYYY':
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];
            const dd = date.getDate();
            const mm = months[date.getMonth()];
            const yy = date.getFullYear();
            return `${dd > 9 ? dd : '0' + dd} ${mm} ${yy}`;
        default:
            return date.toLocaleDateString('en-US');
    }
};
